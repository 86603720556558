import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d68e638c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "waiting-room-overlay" }
const _hoisted_2 = {
  key: 0,
  class: "content-div"
}
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "timer-text" }
const _hoisted_5 = { class: "page-button" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "button-container" }
const _hoisted_8 = { class: "page-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_whitelabel_primary_logo = _resolveComponent("whitelabel-primary-logo")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!

  return (_openBlock(), _createBlock(_component_ContentWrapper, {
    contentWidthMid: "",
    contentBodyBottomMarginDisabled: ""
  }, {
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_whitelabel_primary_logo, { class: "white-label-logo" }),
          (_ctx.isLoading || _ctx.showFailureState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$mhat("OnDemandExamRoom.YourAppointmentStartsSoonHeader")), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$mhat("OnDemandExamRoom.YourAppointmentStartsSoonSubheader")), 1),
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.waitingTime), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$mhat("OnDemandExamRoom.CancelAppointmentMessage")), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_BaseButton, {
                    size: _ctx.ButtonSize.DEFAULT,
                    disabled: _ctx.loadingQueue.isLoading,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat("OnDemandExamRoom.Cancel")), 1)
                    ]),
                    _: 1
                  }, 8, ["size", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isLoading && !_ctx.showFailureState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$mhat("OnDemandExamRoom.AppointmentEndedMessage")), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (!_ctx.isLoading && !_ctx.showFailureState)
                      ? (_openBlock(), _createBlock(_component_BaseButton, {
                          key: 0,
                          pattern: _ctx.ButtonColorPattern.DEFAULT,
                          color: _ctx.ButtonColor.PRIMARY,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onBackToHome()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$mhat("OnDemandExamRoom.GoToHomepage")), 1)
                          ]),
                          _: 1
                        }, 8, ["pattern", "color"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    "content-footer": _withCtx(() => [
      _createVNode(_component_CorporateFooter, {
        branded: "",
        "brand-white-bg": "",
        "show-support-link": false
      })
    ]),
    _: 1
  }))
}