import {
	AttachmentCreateTransfer,
	MessageCreateTransfer,
	MessageCreateTransferRecipients,
} from "@/open_api/generated";

export default class MessageCreateTransferFactory
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create a new message create transfer
	 * @param subject        - subject of the message
	 * @param message        - message body
	 * @param recipients     - list of recipients
	 * @param conversationId - conversation id
	 * @param attachments    - list of attachments
	 */
	public create(subject: string, message: string, recipients: MessageCreateTransferRecipients[],
		conversationId: string, attachments: AttachmentCreateTransfer[]): MessageCreateTransfer
	{
		return {
			subject,
			message,
			recipients,
			conversation_id: conversationId,
			attachments,
		};
	}
}
