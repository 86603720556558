import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a09a8278"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-div text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "timer-text" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_KioskWrapper = _resolveComponent("KioskWrapper")!

  return (_openBlock(), _createBlock(_component_KioskWrapper, { class: "waiting-room-overlay" }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading || _ctx.showFailureState)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", null, _toDisplayString(this.$mhat("KioskWaitingRoomOverlay.HeaderText")), 1),
              _createElementVNode("p", null, _toDisplayString(this.$mhat("KioskWaitingRoomOverlay.WaitingRoomText")), 1),
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.waitingTime), 1),
              _createElementVNode("p", null, _toDisplayString(this.$mhat("KioskWaitingRoomOverlay.CancelAppointmentInformationText")), 1),
              _createVNode(_component_AppButton, {
                fill: "neutral",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("KioskWaitingRoomOverlay.CancelButtonText")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isLoading && !_ctx.showFailureState)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (!_ctx.isLoading && !_ctx.showFailureState)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    pattern: _ctx.ButtonColorPattern.TRANSPARENT,
                    color: _ctx.ButtonColor.PRIMARY,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('exit')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("KioskWaitingRoomOverlay.ExitButtonText")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.pharmacy && _ctx.pharmacy.advertisementFooterUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "ad-image",
            src: _ctx.pharmacy.advertisementFooterUrl,
            alt: "advertisement"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}