import {
	AttachmentCreateTransfer,
	DocumentCreateTransfer,
	MessageCreateTransfer,
	MessageCreateTransferRecipients,
} from "@/open_api/generated";

export default class AttachmentCreateTransferFactory
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create a new attachment create transfer
	 * @param shareWithId - depending on the type of user uploading the document this will be a patient id or a clinic id.
	 * @param name        - name of the document
	 * @param contentType - file content type (mime type)
	 * @param size        - size of the file in bytes
	 * @param base64Data  - base64 encoded file data
	 */
	public create(shareWithId: string, name: string, contentType: string, size: number, base64Data: string): AttachmentCreateTransfer
	{
		return {
			share_with_id: shareWithId,
			name,
			content_type: contentType,
			size,
			data: base64Data,
		};
	}
}
