import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cbb01d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BannerNotificationItem = _resolveComponent("BannerNotificationItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: "notifier",
    onClick: _cache[3] || (_cache[3] = () => _ctx.dismiss(_ctx.NotificationType.Swipe))
  }, [
    _createVNode(_component_BannerNotificationItem, {
      class: _normalizeClass(_ctx.bannerClasses),
      "icon-class": _ctx.iconClass,
      title: _ctx.notification.title,
      message: _ctx.notification.message,
      "has-buttons": !_ctx.isSwipe
    }, {
      "button-slot": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isDismiss)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                small: "",
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.buttonColor,
                onClick: _cache[0] || (_cache[0] = () => _ctx.dismiss())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.dismissText), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color"]))
            : _createCommentVNode("", true),
          (_ctx.isConfirm)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 1,
                small: "",
                pattern: _ctx.ButtonColorPattern.DEFAULT,
                color: _ctx.buttonColor,
                onClick: _cache[1] || (_cache[1] = () => _ctx.dismiss())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.dismissText), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color"]))
            : _createCommentVNode("", true),
          (_ctx.isConfirm)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 2,
                small: "",
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.buttonColor,
                onClick: _cache[2] || (_cache[2] = () => _ctx.confirm())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["class", "icon-class", "title", "message", "has-buttons"])
  ]))
}