import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19da22ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "telehealth-notification-banner-container" }
const _hoisted_2 = { class: "notification-slide-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TelehealthNotificationItem = _resolveComponent("TelehealthNotificationItem")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_ion_slides = _resolveComponent("ion-slides")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.notificationsList && _ctx.notificationsList.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_slides, {
            key: 0,
            pager: "true"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
                return (_openBlock(), _createBlock(_component_ion_slide, { key: index }, {
                  default: _withCtx(() => [
                    (notification.isAppointmentType)
                      ? (_openBlock(), _createBlock(_component_TelehealthNotificationItem, {
                          key: 0,
                          notificationData: notification
                        }, null, 8, ["notificationData"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}