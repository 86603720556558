import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc3066ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "disconnect-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ClinicPage = _resolveComponent("ClinicPage")!

  return (_ctx.showSpinner)
    ? (_openBlock(), _createBlock(_component_PageWrapper, { key: 0 }, {
        "content-center": _withCtx(() => [
          _createVNode(_component_EventStateSpinner, {
            isLoading: _ctx.controller.gettingClinic,
            loadingIcon: "icon-clinic",
            loadingTitle: this.$mhat('ClinicView.LoadingTitle'),
            showFailureState: _ctx.showFailure,
            failureTitle: this.$mhat('ClinicView.LoadingFailureTitle'),
            failureMessage: this.$mhat('ClinicView.LoadingFailureMessage')
          }, null, 8, ["isLoading", "loadingTitle", "showFailureState", "failureTitle", "failureMessage"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_ClinicPage, {
        key: 1,
        clinic: _ctx.controller.clinic,
        "patient-user": this.controller.patientUser
      }, {
        "button-primary": _withCtx(() => [
          (_ctx.controller.isConnectionPendingPatientConfirm)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.ButtonColor.PRIMARY,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controller.handlePatientConfirmationResponse(_ctx.PendingPatientResponse.Confirm)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.Allow")), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color"]))
            : _createCommentVNode("", true),
          (_ctx.controller.clinic.ebookingEnabled && _ctx.controller.bookingStatus === _ctx.ClinicBookingStatus.Unavailable)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 1,
                disabled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.Unavailable")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.controller.isConnectionPendingPatientConfirm
			&& _ctx.controller.clinic.ebookingEnabled
			&& _ctx.controller.bookingStatus === _ctx.ClinicBookingStatus.NotConnected)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 2,
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.ButtonColor.PRIMARY,
                disabled: _ctx.isLoading,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controller.connectToClinic()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.Connect")), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.controller.clinic.ebookingEnabled && _ctx.controller.bookingStatus === _ctx.ClinicBookingStatus.Pending)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 3,
                disabled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.Pending")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.controller.clinic.ebookingEnabled && _ctx.controller.bookingStatus === _ctx.ClinicBookingStatus.Bookable)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 4,
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.ButtonColor.PRIMARY,
                disabled: _ctx.isLoading,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.routeToBooking()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.BookAppointment")), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color", "disabled"]))
            : _createCommentVNode("", true)
        ]),
        "button-secondary": _withCtx(() => [
          (_ctx.controller.isConnectionPendingPatientConfirm)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                pattern: _ctx.ButtonColorPattern.FILL,
                color: _ctx.ButtonColor.PRIMARY,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controller.handlePatientConfirmationResponse(_ctx.PendingPatientResponse.Deny)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.Deny")), 1)
                ]),
                _: 1
              }, 8, ["pattern", "color"]))
            : _createCommentVNode("", true),
          (_ctx.canMessage)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 1,
                onClick: _ctx.routeToComposeMessage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("ClinicView.MessageUs")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        "content-footer": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.canRemove)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  pattern: _ctx.ButtonColorPattern.TRANSPARENT,
                  color: _ctx.ButtonColor.DANGER,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.controller.cancelClinicConnection()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$mhat("ClinicView.DisconnectFromClinic")), 1)
                  ]),
                  _: 1
                }, 8, ["pattern", "color"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["clinic", "patient-user"]))
}