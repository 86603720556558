import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5caf8cc9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BannerNotificationItem = _resolveComponent("BannerNotificationItem")!

  return (_openBlock(), _createBlock(_component_BannerNotificationItem, {
    primary: "",
    "icon-class": "icon-tele-doc",
    "has-buttons": _ctx.showJoinButton,
    title: _ctx.notificationData.title,
    message: _ctx.notificationData.message
  }, _createSlots({ _: 2 }, [
    (_ctx.showJoinButton)
      ? {
          name: "button-slot",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_BaseButton, {
                small: "",
                color: _ctx.ButtonColor.PRIMARY,
                pattern: _ctx.ButtonColorPattern.FILL,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onJoinClick()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.notificationData.actionTitle), 1)
                ]),
                _: 1
              }, 8, ["color", "pattern"])
            ])
          ])
        }
      : undefined
  ]), 1032, ["has-buttons", "title", "message"]))
}