import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffe9f02c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "instruction-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!
  const _component_ClinicLogo = _resolveComponent("ClinicLogo")!
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_ProfileInputFields = _resolveComponent("ProfileInputFields")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_MobileHeaderBar, {
        "on-back-click": _ctx.navigateBack,
        "on-close-click": _ctx.navigateBack,
        "header-text": this.$mhat('PatientProfileModal.HeaderText'),
        icon: null
      }, null, 8, ["on-back-click", "on-close-click", "header-text"])
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isWhiteLabel)
          ? (_openBlock(), _createBlock(_component_ClinicLogo, {
              key: 0,
              clinic: _ctx.clinic
            }, null, 8, ["clinic"]))
          : (_openBlock(), _createBlock(_component_OrgImage, {
              key: 1,
              "asset-type": _ctx.ORG_ASSET_TYPE.LOGO,
              "atl-text": "Organization Logo"
            }, null, 8, ["asset-type"]))
      ]),
      (_ctx.showInstructionText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(this.$mhat("PatientProfileModal.InstructionText")), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_ProfileInputFields, {
        includeSectionHeaders: "",
        includeFieldLabels: "",
        controller: _ctx.profileController,
        inputIds: _ctx.userProfileIdList,
        requiredInputIds: _ctx.userProfileIdList
      }, null, 8, ["controller", "inputIds", "requiredInputIds"])
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_BaseButton, {
        primary: "",
        disabled: !_ctx.canSubmit,
        loading: _ctx.loading,
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("PatientProfileModal.ContinueButtonText")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])
    ]),
    _: 1
  }))
}