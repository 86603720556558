import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c003c6e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row justify-content-end p-t-16 p-b-8" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex flex-row align-items-center p-l-8" }
const _hoisted_4 = { class: "call-state-text text-ellipsis m-l-8 m-r-8" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "spacing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitingRoomOverlay = _resolveComponent("WaitingRoomOverlay")!
  const _component_OnDemandBookingTelehealthWaitingRoomOverlay = _resolveComponent("OnDemandBookingTelehealthWaitingRoomOverlay")!
  const _component_AppChip = _resolveComponent("AppChip")!
  const _component_PulsingIndicator = _resolveComponent("PulsingIndicator")!
  const _component_ChatView = _resolveComponent("ChatView")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_ctx.isRegularTelehealth && !_ctx.inRoom() && _ctx.callOver && !_ctx.showFeedbackOverlay)
    ? (_openBlock(), _createBlock(_component_WaitingRoomOverlay, {
        key: 0,
        onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onExit(false, true, true))),
        isLoading: !_ctx.callOver
      }, null, 8, ["isLoading"]))
    : (_ctx.isAqsTelehealth && !_ctx.inRoom() && !_ctx.showFeedbackOverlay)
      ? (_openBlock(), _createBlock(_component_OnDemandBookingTelehealthWaitingRoomOverlay, {
          key: 1,
          onExit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onExit(false, true, true))),
          "clinic-id": _ctx.clinicId,
          "queue-id": _ctx.queueId,
          "queued-appointment-id": _ctx.queuedAppointmentId,
          isLoading: !_ctx.callOver
        }, null, 8, ["clinic-id", "queue-id", "queued-appointment-id", "isLoading"]))
      : (_openBlock(), _createBlock(_component_PageWrapper, {
          key: 2,
          contentFullHeight: "",
          contentHeaderRemoveFalseHeaderSpace: ""
        }, _createSlots({
          content: _withCtx(() => [
            (!_ctx.showFeedbackOverlay)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_PulsingIndicator, {
                      class: _normalizeClass(["call-indicator", _ctx.chatStateIndicatorClasses]),
                      pulsing: false
                    }, null, 8, ["class"]),
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.remoteConnected())
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$mhat("TelehealthTextChat.ChattingWith", {patientRemoteName: _ctx.getRemoteNames()})), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$mhat("TelehealthTextChat.RoomEmptyMessage")), 1))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ChatView, {
              class: "chat-view flex-item-grow m-t-8",
              onSend: _ctx.sendTextMessage,
              onSendFile: _ctx.sendDocumentMessage,
              onInput: _ctx.singleTyping,
              onFocusin: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showBottomDeadSpace = true)),
              onFocusout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showBottomDeadSpace = false)),
              typing: _ctx.showTypingIndication,
              "chat-items": _ctx.chatItems,
              disabled: !_ctx.remoteConnected(),
              "show-inputs": !_ctx.showFeedbackOverlay
            }, null, 8, ["onSend", "onSendFile", "onInput", "typing", "chat-items", "disabled", "show-inputs"]),
            (_ctx.showBottomDeadSpace)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (!_ctx.showFeedbackOverlay)
            ? {
                name: "content-header",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_AppChip, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onExit(true, false))),
                      color: _ctx.ButtonColor.PRIMARY,
                      pattern: _ctx.ButtonColorPattern.FILL
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$mhat("TelehealthTextChat.Exit")), 1)
                      ]),
                      _: 1
                    }, 8, ["color", "pattern"])
                  ])
                ])
              }
            : undefined
        ]), 1024))
}