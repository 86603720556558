import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91b9a79c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginPageHeader = _resolveComponent("LoginPageHeader")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_LoginPageHeader)
    ]),
    "content-center": _withCtx(() => [
      (_ctx.urlFriendlyName && !_ctx.pharmacy && _ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
            key: 0,
            "is-loading": _ctx.isLoading && !_ctx.pharmacy,
            "loading-icon": "icon-user-md",
            "loading-title": this.$mhat('LoginPage.LoadingTitle')
          }, null, 8, ["is-loading", "loading-title"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_OrgImage, {
              class: "m-b-24",
              assetType: _ctx.ORG_ASSET_TYPE.LOGO,
              atlText: _ctx.brandedLogo.alt
            }, null, 8, ["assetType", "atlText"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.loginMessage), 1),
            _createVNode(_component_LoginForm, {
              "clinic-id": _ctx.clinicId,
              onSuccess: _ctx.handleLoginSuccess,
              onSignup: _ctx.handleSignupRequest
            }, null, 8, ["clinic-id", "onSuccess", "onSignup"])
          ]))
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_CorporateFooter)
    ]),
    _: 1
  }))
}